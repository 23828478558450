import React from 'react'
import download from '../../icons/download.svg'
import download_gray from '../../icons/download_gray.svg'

import {Name, src, message, downloadLink} from '../../OS'

function CTA() {
  return (
    <div class="mt-8">
                <hr class="border-gray-200 sm:mx-auto "/>

    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <span class="block">Ready to dive in?</span>
        <span class="block text-indigo-600">Choose a better way to read.</span>
        </h2>
        <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            {
                Name!=="mobile"?
                <div class="inline-flex rounded-md shadow">
                    <a href={downloadLink?downloadLink:null} class={`
                            inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white 
                            ${downloadLink?"hover:bg-indigo-700 bg-brightDarkPurple ":"cursor-default bg-gray-200 text-gray-600"}                `}> 
                        <img className="w-4 mr-5 inline-block" src={downloadLink?download:download_gray}></img>
                        {downloadLink?`Get for ${Name}`:`Coming soon for ${Name}`}        
                    </a>
                </div>
                :null
            }
        
        <div class="ml-3 inline-flex rounded-md shadow">
            <a href="https://app.desklamp.io" target="_blank" rel="noreferrer" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-lightPurple hover:bg-indigo-200"> Open in Browser </a>
        </div>
        </div>
    </div>
    </div>

  )
}

export default CTA