import React, { useEffect } from 'react';
// import Hero from '../../Hero/Hero'
// import './Download.css'
// import {termsHeaderObj } from './Data'
import TermsContainer from './TermsContainer'
import ReactGA from 'react-ga'
import './TermContainer.css'


function Terms() {
    return (
        <>
         {/* <Hero {...termsHeaderObj}/> */}
         <TermsContainer/> 
        </>
    )
}

export default Terms
