import React from 'react'
import linkedin from '../../icons/social/linkedin.svg'
import Team from './Partials/Team'
import Careers from './Partials/Careers'
import JoinUs from './Partials/JoinUs'

const heading = "inline-block px-3 py-px mb-4 text-4xl font-semibold tracking-wider text-darkPurple  rounded-full"
const subheading = "text-base text-gray-700 md:text-lg"

const  About = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 justify-center">
        

        <Team />
        {/* <JoinUs /> */}
        {/* <Careers /> */}


      </div>
    );
  };



const PersonCard = (props) =>{
    return(
    <div className="grid sm:grid-cols-3">
            <div className="relative w-full h-48 max-h-full rounded shadow sm:h-auto">
              <img
                className="absolute object-cover w-full h-full rounded"
                src={props.image}
                alt="Person"
              />
            </div>
            <div className="flex flex-col justify-center mt-5 sm:mt-0 sm:p-5 sm:col-span-2">
              <p className="text-lg font-bold text-brightDarkPurple">{props.name}</p>
              <p className="mb-4 text-xs text-gray-800">{props.designation}</p>
              <p className="mb-4 text-sm tracking-wide text-gray-800">
                {props.role}
              </p>
              <div className="flex items-center space-x-3">
                  {
                      props.twitter?
                    <a
                    href="/"
                    className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                    <svg viewBox="0 0 24 24" fill="grey" className="h-5">
                        <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                    </svg>
                    </a>
                    :null
                  }
                  {
                      props.linkedin?
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <img src={linkedin}/>
                </a>
                :null
                  }
                
                
              </div>
            </div>
          </div>
    )

}

PersonCard.defaultProps = {
    twitter: true, 
    linkedin: true
}

export default About

