import React from 'react';

import darkMode from '../../../icons/featureIcons/mode.png'
import cloud from '../../../icons/featureIcons/cloud.png'
import marker from '../../../icons/featureIcons/marker.png'
import loupe from '../../../icons/featureIcons/loupe.png'
import pagenum from '../../../icons/featureIcons/pagenum.png'
import back from '../../../icons/featureIcons/back.png'
import collaborate from '../../../icons/featureIcons/collaborate.png'
import wikipedia from '../../../icons/featureIcons/wikipedia-logo.png'
import tags from '../../../icons/featureIcons/tags.png'
import shapes from '../../../icons/featureIcons/shape.png'


function FeaturesBlocks() {
  return (
    <section className="relative mb-20">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 md:mt-10 lg:mt-0 bg-lightPurple pointer-events-none" aria-hidden="true"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-4xl mx-auto text-center pb-12 md:pb-20">
            {/* <h2 className="h2 mb-4">Explore the solutions</h2> */}
            <p className="text-3xl bold rounded-full text-darkPurple">Welcome to the world's most enjoyable PDF experience</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            <FeatureBlock icon={collaborate} title={"Collaborate"} description={"Read and write with teams in real-time."} />
            <FeatureBlock icon={darkMode} title={"Dark Mode"} description={"Read in dark mode for those late night study sessions."} />
            <FeatureBlock icon={pagenum} title={"Page-number Matching"} description={"Match PDF page numbers with the ones in the document."} />
            <FeatureBlock icon={wikipedia} title={"Lookup Tool"} description={"Lookup meanings of words and phrases from within the app."} />
            <FeatureBlock icon={tags} title={"Organize with Tags"} description={"Organize your docs by tagging them into multiple categories."} />
            <FeatureBlock icon={cloud} title={"Backed to the Cloud"} description={"Access your work from any device, and from any place."} />
            <FeatureBlock icon={back} title={"Back Button"} description={"Seamlessly jump back to where you were after clicking a link."} />
            <FeatureBlock icon={loupe} title={"Search Tool"} description={"Search through documents for things that matter most."} /> 
            <FeatureBlock icon={shapes} title={"Annotate"} description={"Annotate PDFs with highlights, shapes, stickers, and more!"} />

            
          </div>

        </div>
      </div>
    </section>
  );
}

const FeatureBlock = (props) =>{
    return(
        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              {/* <svg className="w-16 h-16 p-1 -mt-1 mb-2" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="fill-current text-brightDarkPurple" width="64" height="64" rx="32" />
                  <g strokeWidth="2" strokeLinecap="square">
                    <path className="stroke-current text-white" d="M29.714 40.358l-4.777 2.51 1.349-7.865-5.715-5.57 7.898-1.147L32 21.13l3.531 7.155 7.898 1.147L40 32.775" />
                    <path className="stroke-current text-lightPurple" d="M44.571 43.429H34.286M44.571 37.714H34.286" />
                  </g>
                </g>
              </svg> */}
              <img className="w-12 h-12 p-2 -mt-1 mb-2" src={props.icon}></img>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-2">{props.title}</h4>
              <p className="text-gray-600 text-center">{props.description}</p>
            </div>

    )

}

export default FeaturesBlocks;
