import React from 'react'
import './Support.css'
import BlogList from '../Blog/Partials/BlogList'
import Thumbnail from '../../icons/law_thumb_long2.png'
import { Link } from 'react-router-dom';

import community from '../../icons/support-icons/community.png';
import guide_book from '../../icons/support-icons/guide-book.png';
import videoconference from '../../icons/support-icons/videoconference.png';
import youtube from '../../icons/support-icons/youtube.png';

function Support() {

    const supportBoxes = [
        {
            icon:guide_book ,
            title: "Detailed Guides",
            subtitle: "Read through the docs to learn about everything you can do on Desklamp!", 
            url: "https://app.desklamp.io/read?id=46b203c6-d8df-453d-b546-95a8fa7a44b9&mode=explore",
        },
        {
            icon: videoconference,
            title: "Schedule a Video Call",
            subtitle: "Have a question? Want a personalised demo? Want to share some feedback? Let’s meet!",
            url: "https://calendly.com/desklamp/20min",
        },
        {
            icon: community ,
            title: "Join our Discord Community",
            subtitle: "Hear about updates as they’re released, chat with devs, and engage with other users.",
            url: "https://discord.gg/RFprzE26uf",
        },
        {
            icon: youtube,
            title: "Binge through Tutorials",
            subtitle: "Subscribe to our Youtube Channel to watch tutorials on features and use-cases. ",
            url: "https://www.youtube.com/channel/UCH3T_AK6nFpnDNeLusXBrHg",
        }
    ]


    return(
        <div className="support-container">
            <div className='support-header'>
                <h1 className="support-title">Desklamp Support</h1>
                <h3 className='support-subtitle'>We've got you covered.</h3>
            </div>

            <div className='video-guide-container'>
                <div className='row'>
                    <div className='column image-container'> 
                    <a href="https://youtu.be/DCSsppFqxU0" target="_blank" rel="noreferrer">
                        <img className='video-thumbnail-support' src={Thumbnail} />

                    </a>

                    </div>
                    <div className='column description'> 
                        <div className='description-container'>
                            <h2>New to Desklamp?</h2>
                            <p>Watch this quick video for a guided intro to some of Desklamp’s most powerful features.</p>
                        </div>
                    </div>

                </div>
                

            </div>

            <hr className="border-gray-200 sm:mx-auto "/>

            <div className='support-tools-container'>

                <div className='row'>
                    <div className='column'>
                    {
                        supportBoxes.map((box, index)=>{
                            if(index%2==0){
                                return <SupportBox icon={box.icon} title={box.title} subtitle={box.subtitle} url={box.url} />
                            }

                        }

                        )
                    }
                    </div>

                    <div className='column'>
                    {
                        supportBoxes.map((box, index)=>{
                            if(index%2==1){
                                return <SupportBox icon={box.icon} title={box.title} subtitle={box.subtitle} url={box.url} />
                            }

                        }

                        )
                    }

                    </div>

                </div>

                
            </div>
        </div>
    )
}

function SupportBox(props){

    return(
        <a href={props.url} rel="noreferrer" target="_blank">
            <div className='support-box-container'>
            <div className='row'>
                <div className='support-icon-container column'>
                    <img src={props.icon} className='support-icon' alt="support" />
                </div>
                

                <div className='support-description column'>
                    <p className='support-box-title'>{props.title}</p>
                    <p className='support-box-subtitle'>{props.subtitle}</p>
                </div>
            </div>

        </div>

        </a>
        
    )

}

export default Support