import React from 'react';
// import Modal from '../utils/Modal';

// import HeroImage from '../images/hero-image-01.jpg';
import VideoImage from '../../icons/video_thumb.png';


function Video() {

//   const [videoModalOpen, setVideoModalOpen] = useState(false);

    const subClass = "btn text-brightDarkPurple p-5 rounded bg-lightPurple hover:bg-indigo-200 w-full"

  return (
    <section className="relative">

      {/* Dark background */}
      <div className="absolute inset-0  pointer-events-none -z-10 [clip-path:polygon(0_0,_5760px_0,_5760px_calc(100%_-_352px),_0_100%)]" aria-hidden="true">
        
      </div>

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-16 pb-10 md:pt-16 md:pb-16">

            {/* Hero content */}
            <div className="max-w-2xl mx-auto md:max-w-none md:flex md:items-center md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 md:space-y-0">

              {/* Content */}
              <div className="text-center md:text-left md:min-w-[30rem]" data-aos="fade-right">
                <h1 className="h1 text-4xl font-playfair-display text-darkPurple mb-6">PDFs will never be the <span className="text-brightDarkPurple">same</span>.</h1>
                <p className="text-xl text-textGray mb-12">
                    Getting started with Desklamp is easy. Watch this quick tutorial to see how.
                </p>
                
                <div className="mt-4 max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
            
                  <div>
                    <a className={subClass} href="https://www.youtube.com/watch?v=mRxV47NeUJg" target="_blank" rel="noreferrer">
                        Watch Video
                    </a>
                  </div>
                </div>
              </div>

              {/* Hero image */}
            {/* <div data-aos="fade-left">
                <div className="flex justify-center items-center">
                  <div className="relative">
                    <a href="https://www.youtube.com/watch?v=mRxV47NeUJg" target="_blank" rel="noreferrer">
                        <img className="mx-auto md:max-w-none shadow-xl cursor-pointer" src={VideoImage} width="570" height="" alt="Hero" />
                    </a>
                  </div>
                  
                </div>
                
            </div> */}

            <div className="relative block group" data-aos="fade-right" data-aos-delay="200">
                <div className="absolute inset-0 bg-indigo-200 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-6 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
                {/* <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out"> */}
                    <a href="https://www.youtube.com/watch?v=mRxV47NeUJg" target="_blank" rel="noreferrer">
                        <img className=" inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out shadow-xl" src={VideoImage} width="540" height="303" alt="Blog post 01" />
                    </a>
                {/* </figure> */}
              </div>
              
          </div>

        </div>
      </div>
    </section>
  );
}

export default Video;