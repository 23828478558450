import transform_mp4 from '../static/Transform new.mp4'

const Name="macOS"

const pclass="mb-8"

export const postContent = 
<div>


    <div className="video-container flex justify-center">
        <div className="video-crop flex justify-center text-center  w-400 h-160 overflow-hidden block mb-0"> 
            <center>
            <video className={`cover-video  ${Name}`} height="280px" width="640px"  controls autoPlay loop muted>
                <source src={transform_mp4} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            </center>
        </div>
        
    </div>

    <p className={pclass}>
    Early this year, Gaurav, Prajwal, Abhinav, and I came together with our shared passion for books and our shared disappointment for existing PDF software to build a better platform for digital reading. We called our project Kitab, to signify a new revolution in an industry where things haven’t changed in decades.
            </p>
   
    <p className={pclass}>
    Many of you – especially those who have been using our beta version or have followed our progress closely – already know us as Kitab. Unfortunately, we’ve since realised that we were beaten to the name and couldn’t procure the trademark for <i>Kitab</i>. So, we set out to rename ourselves. 
            </p>

    <p className={pclass}>
    We believe that this is for the best. When we started building Kitab, it was meant to be a new way for students to consume their textbooks digitally. However, as the product grew, we’ve come to realise that its applicability goes far beyond just textbooks. The features we’ve built over the last few months are great for research papers read by collaborative teams, legal documents read by lawyers, and pitch decks read by venture capitalists, just to name a few. Kitab, which means book in Hindi and Urdu, was a great name for the product that we initially set out to build, but started to become too restrictive. Many a time when professionals from sectors outside of academia heard about our app, their first response was, <i>“I don’t actually read too many books.”</i> 
            </p>

    <p className={pclass}>
    There were five points we kept in mind when trying to come up with a new name. For it to:

    </p>

    <ol className="list-disc list-inside mb-8">
    <li>Be easy to remember, spell and pronounce, even for audiences outside of India. </li>
    <li>Not restrict our use-case to just books. </li>
    <li>Project our vibe as a fun and innovative software company. </li>
    <li>Convey (at least to an extent) what our product does. </li>
    <li>Reflect our identity as an Indian company (but not be something too generic that makes us sound like a government service). </li>
    </ol>
    <p className={pclass}>
    For two long weeks, we went on an exhaustive search. We read the entire English dictionary from front to back, consulted an award-winning Hindi poet for her favourite words, and came up with new concoctions that even Dr. Seuss would have been proud of. For every word we came up with, we immediately checked its availability on the trademark registration database. Turns out pretty much every easy-to-say vernacular word is already a registered company in India, which forced us to give up hope on point number five.
    </p>

    <p className={pclass}>
    The name we’ve settled on is <i>Desklamp</i>. 
    </p>

    <p className={pclass}>
    At its core, Desklamp is not much different from a desklamp. It’s a tool you’ll love using at your office table to shine a new light on your reading. Some of you may love our new name; some of you may be a bit apprehensive. To be honest with you, members of our own team had reservations about the name when we first came up with it. However, as we’ve worked on Desklamp over the last month, the name has really started to grow on us and we’ve now fallen in love with it. They say that age is just a number, and a name is just a word. We believe that the names of all great technology companies today are as ubiquitous as they are only because of their association with their products. We’re confident that as you continue to read your documents on Desklamp, that feeling of association will come to you too.
    </p>

    <p className={pclass}>
    We’re really excited about the future of Desklamp. The product is at a stage where it's ready to explode, and a lot more people will begin to know us as Desklamp than ever knew us as Kitab. To those of you who have been a part of our journey since the beginning and have known us as Kitab, know that you’ll always be part of a small group of people very close to our hearts. If you ever bump into us someday, let us know you’ve been with us since the Kitab days, and that would make our day. 

    </p>

    <p className="text-darkPurple">
    We want to give a special thank you to 
    <a className="blog-link mx-1 text-brightDarkPurple underline italic" rel="noreferrer" href=" https://www.instagram.com/this_guy_designs/" target="_blank">
    Ajinkya Gajbhiye</a>, for designing our awesome new logo!
    </p>



    
</div>