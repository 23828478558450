import React from 'react'

const privacyContent = 
        <div className =   {`terms-text`}>
            <h1 className = 'terms-h1 text-4xl'>Privacy Policy</h1>
            <h2 className = {'terms-date'}>Effective Date: November 29, 2021</h2>
            <h2 className = 'terms-h2'>About</h2>
            <p className = 'terms-p'>
            Desklamp Software LLP dba Desklamp (<b>‘Desklamp’</b>, <b>‘we’</b>, <b>‘us’</b>, and <b>‘our’</b>)  understands the matters of user privacy and takes the privacy of its users very seriously. Desklamp’s goal is to take document management and reading to the next level, and this Privacy Policy will help you understand how your data and privacy go hand in hand in achieving this goal.
            </p>
            <h2 className = 'terms-h2'>Scope</h2>
            <p className = 'terms-p'>
            The scope of this document includes any personal information possessed by our business, collected through the desktop application, web application, website or surveys. The Privacy Policy does not apply to third-party websites and services that may be accessible through our applications or websites. By using our services, you agree to be bound by this Privacy Policy. If you do not agree to this Privacy Policy, we request that you discontinue your usage of our services. If you access and use our services on behalf of a company or another legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to this Privacy Policy. In that case, “you” and “your” will refer to that company or other legal entity.
            </p>
            <h2 className = 'terms-h2'>Personal Information Collected by Desklamp</h2>
            <h3 className = 'terms-h3'>Information Provided by You</h3>
            <p className = 'terms-p'>
            <ul>
                <li><b>Account Information</b> - Upon accessing the Desklamp web app or the Desklamp desktop app for the first time, you will be prompted to create an account, wherein we will collect personal information such as your name, email address, and profession.</li>
                <li><b>Communication with Desklamp</b>  - When you communicate with us through email, contact or survey forms, we collect your personal information which may include, but is not limited to, your name, email address and any preferences that you may state in these communications.</li>
                <li><b>Usage of the Service</b> - As you use Desklamp services, you also provide us with whatever information you save or send through these services. This may include documents, notebooks, and any other work that you upload, save, or perform on Desklamp. When you share your work with other users on Desklamp, keep in mind that these users may be able to save that content or copy and distribute it outside the Desklamp app. Desklamp will not be held accountable or responsible if any information or content that is sensitive to you is distributed by users with whom you have shared that information. It is your responsibility to ensure that you do not share content that you wouldn’t want other users to save or share.</li>
            </ul></p>
            <h3 className = 'terms-h3'>Information That is Collected Automatically</h3>
            <p className = 'terms-p'>
            <i>
                This section describes the information that is collected automatically when you use our services. Once you have agreed to the Privacy Policy, explicit consent will not be asked each time information is collected. We may also update our Privacy Policy at any point, and it is your responsibility to keep yourself up to date with any changes in the same.
            </i>
            </p>
            <p className = 'terms-p'><ul>
                <li><b>Automatic Data Collection</b> - When you use our services, we may collect certain information such as your IP address (and the inferred location information), MAC address, cookie identifiers, information about your browser, operating system or device, and information about how you interact with and use the service. 
</li>
                <li><b>Analytics</b>  - We use third-party services such as Google Analytics to monitor and collect information about our services. This includes, but is not limited to your personal information, information that can uniquely identify your device and how you interact with our services. For more information about how Google uses data, please visit www.google.com/policies/privacy/partners/. You can opt out of Google Analytics’ collection and processing of data generated by your use of our services by going to http://tools.google.com/dlpage/gaoptout. 
                <li><b>Cookies, Pixel Tags, and Web Beacons</b> - 
                We may use cookies, pixel tags, web beacons, your local storage or other similar technologies to automatically collect personally identifiable information about your preferences, or interactions with our services. These technologies enable us to store data files in your local storage and collect relevant information                </li>
                </li><li><b>Log Information</b> - We collect log information from when you use our website and web or desktop application such as :
                <ul className = {`nested-ul`}>
                    <li>details about how you’ve used our services;</li>
                    <li>device information, such as your web browser type and language;</li>
                    <li>access times;</li>
                    <li>pages viewed;</li>
                    <li>IP address;</li>
                    <li>identifiers associated with cookies or other technologies that may uniquely identify your device or browser; and</li>
                    <li>pages you visited before or after navigating to our website.</li>
                </ul>
                </li>
                <li><b>Cross-Device Tracking</b> - Your activity on our services may be tracked and synced on the various devices or browsers that you may use to access our services.</li>
            </ul></p>
            <h3 className = 'terms-h3'>Information That We May Recieve From Elsewhere</h3>
            <p className = 'terms-p'>
            We may receive information about you that may or may not be personally identifiable from verified third party marketing agencies. Desklamp is in no way responsible for the legitimacy and accuracy of the information we receive. Desklamp is also not responsible for auditing the means in which the information reaching us is collected.
            </p>
            <h2 className = 'terms-h2'>How We Use Your Information</h2>
            <h3 className = 'terms-h3'>To Provide Services Requested by You</h3>
            <p className = 'terms-p'>
            The personal information we collect may be used by us to manage and provide our services to you. These may include activities such as 
            <ul>
                <li>Managing your account information</li>
                <li>Providing access to certain features and functionalities of our service based on your account information.</li>
            </ul>
            </p>
            <h3 className = 'terms-h3'>To Improve Services Provided to You</h3>
            <p className = 'terms-p'>
            Desklamp uses the information we collect to relentlessly update and improve the products and services we provide. This may include:
            <ul>
                <li>Identification of software bugs in our products</li>
                <li>Research and development to enhance the user interface and experience of existing features, or to introduce new features into our services</li>
                <li>Measuring interest and engagement in our services.</li>
                <li>Ensuring internal quality control</li>
                <li>Marketing and Sales</li>
            </ul>
            </p>
            <h3 className = 'terms-h3'>Use of Deentified Information</h3>
            <p className = 'terms-p'>
            De-identified information can consist of any of the information that we collect using the means stated above that cannot be traced back to you.  De-identified and/or aggregated information is not personal information, and we may use and disclose such information in a number of ways, including research, industry analysis, analytics, and any other legally permissible purposes. 
            </p>
            <h2 className = 'terms-h2'>How We Share Your Information</h2>
            <p className = 'terms-p'>As you use our services, information about you may be shared in the following ways:</p>
            <p className = 'terms-p'>
                <ul>
                    <li><b>With Other Users of Desklamp</b> - Desklamp allows you to collaborate and share your work with other users on the service. While sharing your work with other users of the service, your personal information such as account name and email address may be disclosed to them.</li>
                    <li><b>Third-Party Service Providers</b> - Some of our services may be provided with third-party integrations which we use to assist us in providing you with our services. For example, we use AWS to store your basic account details and any usage data. Through these integrations, you may be providing information to the third party as well as to Desklamp. We are not responsible for how those third parties collect or use your information.</li>
                    <li><b>Your Organisation</b> - If you are accessing our services on behalf of an organisation/ are part of an organisation, we may share your personal information as requested by the organisation.</li>
                    <li>
                        <b>For Legal Reasons</b> - We may share information about you if we are led to believe that disclosing the information is needed to:
                        <ul>
                            <li>comply with any valid legal process, governmental request, or applicable law, rule, or regulation.</li>
                            <li>investigate, or enforce potential Terms of Service violations.</li>
                            <li>protect the rights, property, or safety of us, our users, or others</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <h2 className = 'terms-h2'>How Long We Keep Your Information</h2>
            <p className = 'terms-p'>
                <ul>
                    <li>We store your basic personal account information – like your name, email address, etc. – until you ask us to delete them.</li>
                    <li>Any work that you perform on Desklamp such as documents you upload, notebooks you create, highlights you add, et cetera can be deleted by you at any time from within the app. Once you delete such information from your end, we may continue to keep this information saved on our servers for a temporary duration (usually 30 days).  Post this temporary duration, we will take all necessary measures to permanently delete these data from our servers. Please note that even after the aforementioned work data is deleted from our servers, we may still keep a record that such data was once created by you through our services.</li>
                </ul>
            </p>
            <h2 className = 'terms-h2'>Children</h2>
            <p className = 'terms-p'>Our services are not intended for – and we don’t direct them to – anyone under the age of 13. For that reason, we do not knowingly collect any personal information from anyone under 13. If you are a parent of a child under the age of 13 who has provided us with personal information without your consent, please contact us at the details provided below and we will promptly take steps to terminate the child’s account and delete all such information.</p>
            <h2 className = 'terms-h2'>Changes to Privacy Policy</h2>
            <p className = 'terms-p'>We may revise this privacy policy from time to time. But when we do so, we will take reasonable measures to ensure that you are informed as soon as we do so. Some of the measures we may take to inform you of such changes are through in-app notifications, emails, and revising the date at the top of the privacy policy accessible through our website, web application, and desktop application. If you continue to use our services after a new Privacy Policy takes effect, you understand that you will be deemed to have accepted the new terms laid out by the updated Privacy Policy. If you do not agree to the terms laid out by an updated Privacy Policy, you should stop using our services.</p>
            <h2 className = 'terms-h2'>International Data Transfers</h2>
            <p className = 'terms-p'>All information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, India or other countries, which may have data protection laws that are different from the laws where you live.</p>
            <h2 className = 'terms-h2'>What Rights Do You Have?</h2>
            <p className = 'terms-p'>
                <ul>
                    <li>You may have the right to opt-out of the usage of some of your personal information.</li>
                    <li>You may request correction of your personal information in case it has been wrongly recorded in our services.</li>
                    <li>You may request the deletion of your personal information.</li>
                    <li>You may request access to your personal information and details about your personal information such as
                    <ul className = {`nested-ul`}>
                        <li>Whether your personal information is currently being processed by us</li>
                        <li>Whether your personal information has been shared with third party services</li>
                    </ul>
                    </li>
                </ul>
            </p>
        </div>

export default privacyContent
