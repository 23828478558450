import React, {useState, useEffect} from 'react'
// import {src,message,Name} from '../../OS.js'
import termsContent from './Data/TermsOfService.js'
import privacyContent from './Data/PrivacyPolicy.js'
import './TermContainer.css'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

const Name="macOS"

function TermsContainer() {

    const page = window.location.href.split('=')[1]||'terms'

    if (process.env.NODE_ENV !== 'development'){
        ReactGA.initialize(process.env.REACT_APP_GA_WEBSITE)
        ReactGA.set({ page: `Legal_${page}` });
        ReactGA.pageview(`/legal_${page}`);
    }

    // const [page, setPage] = useState(defaultPage)
    const [ready, setReady] = useState(false)
    let privacy,terms
    useEffect(() => {
        // Update the document title using the browser API
        terms = document.getElementById("terms");
        privacy = document.getElementById("privacy");
        setReady(true)
        console.log('window href', window.location.href)
      });
    
    // const changePage = (e) =>{
    //     console.log('Change page', e.target)
    //     setPage(e.target.id)
    // }

    // const scrollTerms = ()=>{
    //     if(ready){
    //     var headerOffset = 100;
    //     var elementPosition = terms.getBoundingClientRect().top;
    //     var offsetPosition = elementPosition - headerOffset;
    //     if(!termState){
    //         console.log('success')
    //         setTermState(!termState)
    //         setPrivacyState(!privacyState)
    //     }
    //     terms.scrollIntoView({
    //         top: offsetPosition,
    //         behavior: "smooth"
    //     })
    //     }
        
    // }

    // const scrollPrivacy = ()=>{
    //     if (ready){
    //     var headerOffset = 100;
    //     var elementPosition = privacy.getBoundingClientRect().top;
    //     var offsetPosition = elementPosition - headerOffset;
    //     if(!privacyState){
    //         setPrivacyState(!privacyState)
    //         setTermState(!termState)
    //     }
    //     privacy.scrollIntoView({
    //         top: offsetPosition,
    //         behavior: "smooth"
    //     })
    //     }
        
    // }


    return (
        <div className={` terms-container ${Name}`}>
            <div className={`nav-container ${Name}`} >
                <a id = 'terms' className={`terms-nav-link ${page==='terms'}`} href={'/legal?page=terms'}>Terms of Service</a>
                <a id = 'privacy' className={`terms-nav-link ${page==='privacy'}`} href={'/legal?page=privacy'} >Privacy Policy</a>
            </div>
            <div className = {`terms-text-container ${Name}`}>
                <div id = 'terms' className = {`terms ${Name}`} style = {{
                    display:page==='terms'?'block':'none'
                }}>
                {termsContent}
                </div>
                <div id = 'privacy' className = {`privacy ${Name}`} style = {{
                    display:page==='privacy'?'block':'none'
                }}>
                {privacyContent}
                </div>
            </div>
            
        </div>
    )
}

export default TermsContainer
