import React from 'react'

function FeatureHero(props) {
    /**
     * @props: image (img source), caption, title, description, featurePoints (array of strings)
     */

    const {image} = props

  return (
        <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up">
                <img className="max-w-full mx-auto md:max-w-none h-auto shadow-lg" src={image} width="640" height="405" alt="Features 01" />
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="text-xl text-brightDarkPurple mb-2">{props.caption}</div>
                  <h3 className="text-3xl h3 mb-3">{props.title}</h3>
                  <p className="text-xl text-textGray mb-4">{props.description}</p>
                  <ul className="text-lg text-textGray -mb-2">
                      {
                          props.featurePoints.map(feature =>
                            <li className="flex items-center mb-2">
                                <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                </svg>
                                <span>{feature}</span>
                            </li>
                          )
                      }
                   
                  </ul>
                </div>
              </div>
        </div>
  )
}

export default FeatureHero