const termsContent = 
<div>
<h1 className = 'terms-h1 text-4xl'>Terms of Service</h1>
<h2 className = 'terms-date'>Effective Date: November 29, 2021</h2>
<p className = 'terms-p'>
This End Users License Agreement (<b>EULA</b>) constitutes a firm and binding agreement between [Desklamp](‘<b>Desklamp</b>’) and the users (<b>Users</b>) access and/or use of the [Product]/[Services] (as defined below) provided by Desklamp on this website, web and desktop application (<b>Platforms</b>). The term you, your, yourself is used for the Users in this EULA.
</p>
<p className = 'terms-p'>
This EULA is an electronic record in terms of the Information and Technology Act, 2000 and the rules made thereunder. 
</p>
<p className = 'terms-p'>
Please read the terms of this EULA and Privacy Policy carefully before using or registering on the Platform or accessing any material, information, Product or Services through the Platform. Your use of the Platform, Product or the  Services signifies your acceptance of this EULA and Privacy Policy and your agreement to be legally bound by the same. 
</p>
<p className = 'terms-p'>
For the purposes of this EULA,
</p>
<p className = 'terms-p'>
‘Content’ [means any text, information, or material, such as electronic documents, or images, that you upload and import into, or create with the Services or Product in connection with or through your use of the Services.]
</p>
<p className = 'terms-p'>
‘Product’ shall mean [the software used on this website] and includes upgrades, updates, enhancements and push notifications provided from time to time.
</p>
<p className = 'terms-p'>
ACCESSING, BROWSING OR OTHERWISE USING THE SERVICES AND/OR THE SOFTWARE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THIS EULA, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
</p>
<h2 className = 'terms-h2'>Use of the Product and Service</h2>
<p className = 'terms-p'>
You acknowledge that the [Product] will only be used by you for availing the Services in accordance with the terms and conditions set forth in this EULA and in no other manner whatsoever.  
</p>
<h2 className = 'terms-h2'>Licenses</h2>
<p className = 'terms-p'>
Subject to the EULA and its terms and conditions, Desklamp hereby grants you a limited, non-exclusive, non-transferable, license to [install, use and access the Product]. The Product is being licensed to you and you hereby acknowledge that no title or ownership in the Product is being transferred or assigned and this EULA should not be construed as a sale of any rights in the Products.
</p>
<h2 className = 'terms-h2'>Terms of Use</h2>
<p className = 'terms-incr-p'>
This EULA set out the whole agreement between you and Desklamp for the use of the Product and the Services. [In order to participate in certain Services, you may be required to agree to additional terms and conditions (payment terms, cancellation policy, exchange policy, return policy, etc.) which are briefly mentioned below. In the event the terms of this EULA are inconsistent with such additional terms, then such additional terms shall prevail.]
</p>
<p className = 'terms-incr-p'>
Please check that the details in these EULA are complete and accurate before you use or commit yourself to availing the Platform Services. If you think that there is a mistake, please make sure that you ask us to confirm any changes in writing. 
</p>
<p className = 'terms-incr-p'>
<b>Manner of Acceptance of Terms of Use</b><br/>Use of and access to the Platform is offered to you upon the condition of acceptance of all the terms, conditions and notices contained in this EULA, along with any amendments made by Desklamp at its sole discretion and posted on the Platform. By (i) using this Platform or any Services in any way; or (ii) merely browsing the Platform, you agree that you have read, understood and agreed to be bound by the EULA available at our website and desktop application.
</p>
<p className = 'terms-incr-p'>
<b>Storage of Personal Information</b><br/>As part of your use of the Platform, you affirmatively consent that you have read and understood this EULA and Privacy Policy and hereby consent to the collection, processing, storage, disclosure and/or transfer of your personal information in accordance with the Privacy Policy. You further agree and confirm that the security practices and procedures implemented by Desklamp are reasonable and sufficient for the protection of your personal information that is collected and stored by Desklamp.  [For information about how we collect, use, share, or otherwise process information about you and your use of our apps and websites, please see our Privacy Policy.]
</p>
<p className = 'terms-incr-p'>
<b>Change of terms of EULA </b><br/>Desklamp reserves the right to change or update these terms, or any other of our policies or practices, at any time, and will notify Users by posting such changed or updated Terms to [www.desklamp.io/legal] or on our mobile application. Your continued use of the Platform constitutes your agreement to abide by the terms as modified. Under certain circumstances, we may also elect to notify you of changes or updates to our terms by additional means, such as pop-up or push notifications or through e-mail notifications. 
</p>
<p className = 'terms-incr-p'>
<b>Usage of personal information and communication</b><br/>Desklamp shall use your Personal Information to ensure services with respect to the Platform are presented to you in the most effective manner, to secure the Platform and make improvements, to carry out our obligations to You, and to communicate with you. The said communication can either be by calls, text or emails and for purposes which include transactional, service, or promotional calls or messages. If at any time You wish to not receive any communication from our end, you can opt-out of the same by writing to us on [support@desklamp.io].
</p>
<h2 className = 'terms-h2'>Software Updates</h2>
<p className = 'terms-p'>
Desklamp or such other third party may make available to you updates, upgrades, supplements and add-on components of the Product including bug fixes, service upgrades and feature improvements after the date you obtain your initial copy of the Product. This EULA shall apply to all and any component of the update, unless other terms are made available with such updates. Desklamp shall provide the updates, upgrades, enhancements entirely at its discretion and nothing in this EULA shall be deemed to impose an obligation on Desklamp to provide the aforesaid.
</p>
<h2 className='terms-h2'>Storage</h2>
<p className = 'terms-p'>The Product may permit storage of your Content and Desklamp may create reasonable technical limits on file size, storage space, processing capacity, and other technical limits. The Services may be suspended until you are within the storage space limit associated with your account. [At the end of your license term, we will use commercially reasonable efforts to allow you to transition your Content out of the Services. The transition must be completed within 30 days from the date of the termination or expiration of your license term. At the end of this 30-day transition period, we reserve the right to delete your Content. You should download any Content that you have stored in the Services before your license ends.]</p>
<h2 className = 'terms-h2'>Prerelease of Beta Version</h2>
<p className = 'terms-p'>
Desklamp may designate the Services or Software, or a feature of the Services or Software, as a prerelease or beta version (“<b>Beta Version</b>”). A Beta Version does not represent the final product and may contain bugs that may cause system or other failure and data loss. Desklamp may choose not to release a commercial version of the Beta Version. You must promptly cease using the Beta Version and destroy all copies of the Beta Version if we request you to do so. In exchange for your use of a Beta Version, you agree that Desklamp may collect data regarding your use of the Beta Version to improve our products and personalize your experience, regardless of whether or not you have opted-out of data collection for non-Beta Versions. If you do not wish to have your usage tracked, you must discontinue your use of the Beta Version by uninstalling such Beta Version or utilizing a non-Beta Version of the Services or Software. Any separate agreement we enter into with you governing the Beta Version will supersede these provisions.
</p>
<h2 className = 'terms-h2'>Content</h2>
<p className = 'terms-incr-p'>You must not upload and/or use the Services for any Content that is prohibited by any applicable law. Desklamp reserves the right to remove Content or restrict access to Content, Services, and Products if any of your Content is found to be in violation of these Terms. Desklamp does not review all Content uploaded to the Services or Products, but may use available technologies, vendors, or processes to screen for certain types of illegal content or other abusive content or behavior.</p>
<p className = 'terms-incr-p'><b>Ownership of the Content</b></p>
<p className = 'terms-p'>Desklamp shall not claim ownership of your Content and you (as a business or an individual, as applicable) retain all rights and ownership of your Content.</p>
<p className = 'terms-incr-p'><b>Access to Content.</b></p>
<p className = 'terms-p'>Desklamp will only access, store and view your Content in limited ways to enable it to perform the Services. You agree to provide access to Desklamp to save, store and view your Content to: (A) respond to support requests; (B) detect, prevent, or otherwise address fraud, security, legal, or technical issues; and (C) enforce the terms of this EULA. Our automated systems may analyze your Content using techniques such as machine learning in order to improve our Services and Products and the user experience.</p>
<p className = 'terms-incr-p'><b>Termination of License</b></p>
<p className = 'terms-p'>[You may revoke this license to your Content and terminate our rights at any time by removing your Content from the Service. However, some copies of your Content may be retained as part of our routine backups. ]</p>
<p className = 'terms-incr-p'><b>Feedback</b></p>
<p className = 'terms-p'>You have no obligation to provide us with ideas, suggestions, proposals, or bug or crash reports (“Feedback”). If you submit Feedback to us however, then you grant us a non-exclusive, irrevocable, perpetual, worldwide, royalty-free, sublicensable, and transferable license to make, use, sell, have made, offer to sell, import, export, reproduce, publicly display, distribute, modify, and publicly perform the Feedback.</p>
<h2 className = 'terms-h2'>Account Information.</h2>
<p className = 'terms-p'>You are responsible for all activity that occurs via your account even if that activity is not by you or is without your knowledge or consent. Please notify Customer Support immediately if you become aware of any unauthorized use of your account. You may not (A) share your account information (except with an authorized account administrator), whether intentionally or unintentionally; or (B) use another person’s account. Your account administrator may use your account information to manage your use and access to the Services and Product.</p>
<h2 className = 'terms-h2'>User Conduct</h2>
<p className = 'terms-p'>You must not use the Services, the Product or the Platform which in any manner is against the terms of the EULA, the applicable laws, the Privacy Policy including but not limited to the following:</p>
<p className = 'terms-p'>(A) [use the Services or Product without, or in violation of, a written license or agreement with Desklamp];</p>
<p className = 'terms-p'>(B) copy, modify, host, stream, sublicense, or resell the Services or Product;</p>
<p className = 'terms-p'>(C) enable or allow others to use the Services or Product using your account information;</p>
<p className = 'terms-p'>(D) offer, use, or permit the use of the Services or Product in a computer services business, third-party outsourcing service, on a membership or subscription basis, on a service bureau basis, on a time-sharing basis, as a part of a hosted service, or on behalf of any third party;</p>
<p className = 'terms-p'>(E) [use the Product to construct any kind of database or dataset];</p>
<p className = 'terms-p'>(F) access or attempt to access the Services or Products by any means other than the interface we provide or authorize;</p>
<p className = 'terms-p'>(G) circumvent any access or use restrictions put into place to prevent certain uses of the Services or Product;</p>
<p className = 'terms-p'>(H) share Content or otherwise engage in behavior that violates anyone’s Intellectual Property Rights. “Intellectual Property Rights” means copyright, moral rights, trademark, trade dress, patent, trade secret, unfair competition, right of privacy, right of publicity, and any other proprietary rights;</p>
<p className = 'terms-p'>(I) share any Content that is unlawful, harmful, threatening, obscene, violent, abusive, tortious, defamatory, libelous, vulgar, lewd, profane, invasive of another’s privacy, hateful, or otherwise objectionable;</p>
<p className = 'terms-p'>(J) attempt to disable, impair, or destroy the Services or Product;</p>
<p className = 'terms-p'>(K) upload, transmit, store, or make available any Content, or code that contains any viruses, malicious code, malware, or any components designed to harm or limit the functionality of the Services or Product;</p>
<p className = 'terms-p'>(L) disrupt, interfere with, or inhibit any other user from using the Services or Product;</p>
<p className = 'terms-p'>(M) place an advertisement of any products or services in the Services except with our prior written approval;</p>
<p className = 'terms-p'>(N) use any data mining or similar data gathering and extraction methods in connection with the Services or Product, including data scraping for machine learning or other purposes;</p>
<p className = 'terms-p'>(O) artificially manipulate or disrupt the Services or Product;</p>
<p className = 'terms-p'>(P) create accounts for the purpose of violating these terms or for circumventing account termination or other types of actions taken by Desklamp; </p>
<p className = 'terms-p'>(Q) manipulate or otherwise display the Services or Software by using framing or similar navigational technology; or</p>
<p className = 'terms-p'>(R) violate applicable law.</p>
<h2 className = 'terms-h2'> Warranty and Indemnification Obligations</h2>
<p className = 'terms-incr-p'><b>Warranty</b></p>
<p className = 'terms-p'>By uploading your Content to the Services or Product, you agree that you have all necessary licenses and permissions to use and share your Content; and shall be solely liable for any and all liabilities from the use of such Content as shared by you.</p>
<p className = 'terms-incr-p'><b> Indemnification</b></p>
<p className = 'terms-p'>You will indemnify us and our subsidiaries, affiliates, officers, agents, employees, partners, and licensors from any claim, demand, loss, or damage, including reasonable attorneys’ fees, arising out of or related to your Content, your use of the Services or Products (as the case may be), or your violation of the terms of the EULA. Desklamp shall have the right to control the defense of any claim, action, or matter subject to indemnification by you with counsel of our own choosing. You will fully cooperate with us in the defense of any such claim, action, or matter.</p>
<h2 className = 'terms-h2'> Intellectual Property Rights</h2>
<p className = 'terms-incr-p'>
The Product, the Platform and the processes, and their selection and arrangement, including but not limited to, all text, videos, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (and any combinations thereof) on the Platform and the Products is owned and controlled by Desklamp and / or its licensors and the design, structure, selection, coordination, expression, look and feel and arrangement of such Platforms and Products is protected by copyright, patent and trademark laws, and various other intellectual property rights. You are not permitted to use the any of the above without the prior consent of Desklamp, or the third party that may own the copyright, patent, trademark or any other intellectual property right in such content. 
</p>
<p className = 'terms-incr-p'>
Desklamp shall remain the sole owner of all right, title, and interest in the Services and the Product. Unless specifically stated in the EULA, the intellectual property rights to patents, copyrights, trade secrets, trademarks, or any other rights in respect to the items in the Services or the Products, shall not be deemed to be granted to the Users in any manner whatsoever. 
</p>
<p className = 'terms-incr-p'>
The trademarks, logos and service marks displayed on the Product and the Platform (“<b>Marks</b>”) are the property of Desklamp, as the case may be. You are not permitted to use the Marks without the prior consent of Desklamp. 
</p>
<h2 className = 'terms-h2'>Disclaimers of Warranties</h2>
<p className = 'terms-incr-p'>
Unless stated otherwise, the Services and Product are provided on an as-is on an “as is” and on “as available” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing and notwithstanding anything else contained in the EULA, Desklamp makes no warranty that: (i) the Product or the Services will meet your requirements or your use of the Product, Services or Platform will be uninterrupted, timely, secure or error-free; (ii) the results that may be obtained from the use of the Product or Services will be effective, accurate or reliable; (iii) the quality of the Product or Services will meet your expectations; or (iv) any errors or defects in the Product or Services will be corrected. No advice or information, whether oral or written, obtained by you from Desklamp or through use of the Platform shall create any warranty not expressly stated in the EULA.
</p>
<p className = 'terms-incr-p'>
Desklamp specifically disclaim all liability for any actions resulting from your use of any Services or Product. You may use and access the Product or Services at your own discretion and risk, and you are solely responsible for any damage to your computer system or loss of data that results from the use of and access to any Product or Service.
</p>
<p className = 'terms-incr-p'>
Desklamp will have no liability related to any Content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. Desklamp also disclaims all liability with respect to the misuse, loss, modification or unavailability of any Content.    
</p>
<p className = 'terms-incr-p'>
Desklamp has endeavored to ensure that all the information on the Platform is correct, but Desklamp neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, or Product. The Company shall not be responsible for the delay or inability to use the Platform, Product or Services or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict liability or otherwise.  
</p>
<p className = 'terms-incr-p'>
Desklamp shall not be held responsible or liable for any damages arising from periodic maintenance operations or unplanned interruption/ suspension of Product and/or the Services that may occur due to technical reasons or for any reason beyond Desklamp’s control or termination of Services in accordance with the terms of this EULA. 
</p>
<p className = 'terms-incr-p'>
If you post your Content on our servers to publicly share through the Services, we are not responsible for: (A) any loss, corruption, or damage to your Content; (B) the deletion of Content by anyone other than Desklamp; or (C) the inclusion of your Content by third parties on other websites or in other media.
</p>
<h2 className = 'terms-h2'>Limitation of Liability</h2>
<p className = 'terms-incr-p'>
Desklamp shall not be liable to you or anyone else for any special, incidental, indirect, consequential, moral, exemplary or punitive damages whatsoever, regardless of cause, including losses and damages (A) resulting from loss of use, data, reputation, revenue, or profits; (B) based on any theory of liability, including breach of contract or warranty, negligence, or other tortious action; or (C) arising out of or in connection with your use of or access to the Services or Product.
</p>
<p className = 'terms-incr-p'>
Notwithstanding anything contained to the contrary in this EULA, Desklamp’s total liability in any matter arising out of or related to the EULA is limited to [the aggregate amount that you paid for access to the Services and Product] during the [one month] period preceding the event giving rise to the liability.  
</p>
<h2 className = 'terms-h2'>Termination</h2>
<p className = 'terms-incr-p'>
<b>Termination by User</b>
</p>
<p className = 'terms-p'>
You may stop using the Product and the Services at any time. [Termination of your account does not relieve you of any obligation to any outstanding payments due to Desklamp.] To delete your account, contact us at [support@desklamp.io].
</p>
<p className = 'terms-incr-p'>
<b>Termination by Desklamp</b> 
</p>
<p className = 'terms-p'>Desklamp may terminate the EULA for convenience by making reasonable efforts to notify you at least 30 days prior to termination via the email address provided by you. In the event of such termination Desklamp shall also endeavour to provide instructions on how to retrieve your Content. Please note you may lose access to your Content upon termination.</p>

<p className = 'terms-p'>Desklamp may terminate or suspend your right to use and access the Product and/or the Services if:</p>

<p className = 'terms-p'>(A) you breach any provision of the EULA (or act in a manner that clearly shows you do not intend to, or are unable to, comply with the EULA);</p>

<p className = 'terms-p'>(B) [you fail to make the timely payment of fees for the Product or Services;]</p>

<p className = 'terms-p'>(C) you physically, verbally, or through other means abuse, threaten, bully, or harass Desklamp or our personnel (in such circumstances, we may alternatively suspend or restrict your access to the Product or Services);</p>

<p className = 'terms-p'>(D) you have repeatedly made complaints in bad faith or without a reasonable basis, and continue to do so after we have asked you to stop (in such circumstances, we may alternatively suspend or restrict your access to the Product or Services); </p>

<p className = 'terms-p'>(E) we are required to do so by law;</p>

<p className = 'terms-p'>(F) we elect to discontinue the Product or Services, in whole or in part; or</p>

<p className = 'terms-p'>(G) [there has been an extended period of inactivity in your free account. ]</p>

<p className = 'terms-p'>Upon termination of this EULA, you must cease all use of the Product and Services and destroy all copies, full or partial of the Product.</p>
<h2 className = 'terms-h2'> Dispute Resolution</h2>

<p className = 'terms-incr-p'>
<b>Governing Law </b>
</p>
<p className = 'terms-p'>These terms shall be governed by and construed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, fora, applicable authorities at New Delhi.
</p>
<p className = 'terms-incr-p'>
<b>Agreement to Arbitration</b>
</p>
<p className = 'terms-p'>The Parties agree that any dispute, claim or controversy arising out of or in connection to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Platform or Product or Services (“<b>Dispute</b>”) will be settled by a binding arbitration (“<b>Arbitration</b>”).   
</p>
<p className = 'terms-p'>All Disputes, which you or Desklamp (“<b>Disputing Party</b>”) wishes to have resolved, shall be referred upon the application of either the Disputing Party or the other party to the Dispute and finally settled under [(Indian) Arbitration and Conciliation Act, 1996 (“<b>Act</b>”) and the rules made thereunder], in force at the date of intimation of such Dispute. The number of arbitrators shall be 3. One (1) arbitrator shall be appointed by the Disputing Party and one (1) arbitrator shall be appointed by the other party to the Dispute and together the 2 arbitrators so appointed shall appoint the 3rd arbitrator. If within 14 days of a request from the other party to do so a party fails to nominate an arbitrator, or if the 2 arbitrators fail to nominate the 3rd third arbitrator within 14 days after the appointment of the 2nd arbitrator, the appointment shall be made in accordance with the Act.</p>

<p className = 'terms-p'>The seat and venue of such arbitration will be [Bengaluru]. The language of this arbitration shall be English.</p>

<p className = 'terms-p'>Any award of the arbitrator or arbitral tribunal, as the case may be, pursuant to this clause shall be in writing and shall be final, conclusive and binding upon the parties, and the parties shall be entitled (but not obliged) to enter judgment thereon in any one or more of the highest courts having jurisdiction.</p>

<p className = 'terms-incr-p'>
<b>Class Action waiver</b>
</p>
<p className = 'terms-p'>
Where permitted under the applicable law, you and Desklamp agree that each may bring claims against the other only in your or its individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding. Further, where permitted under the applicable law, unless both you and Desklamp agree otherwise, the court may not consolidate more than one person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding.
</p>
<h2 className = 'terms-h2'>
Miscellaneous
</h2>
<p className = 'terms-incr-p'>
The English version of the Terms will be the version used when interpreting or construing the Terms.
</p>
<p className = 'terms-incr-p'>
Notices: To Desklamp. You may send notices to us at the following email address: [support@desklamp.io ]. Desklamp may notify you by email, postal mail, postings within the Services, or other legally accepted means. It is your responsibility to keep your account information current to receive notifications.     
</p>
<p className = 'terms-incr-p'>
You may not assign or otherwise transfer the EULA or your rights and obligations under the EULA, in whole or in part, without our written consent, and any such attempt will be void. Desklamp may transfer our rights under the EULA to a third party.   
</p>
<p className = 'terms-incr-p'>
Headings used in the EULA are provided for convenience only and will not be used to construe meaning or intent.  
</p>
<p className = 'terms-incr-p'>
If any provision of the EULA is held invalid or unenforceable for any reason, the remainder of the EULA will continue in full force and effect.   
</p>
<p className = 'terms-incr-p'>
Any failure to enforce or exercise any provision of the EULA is not a waiver of that provision.  
</p>
<p className = 'terms-incr-p'>
Neither party will be liable to the other for any delay or failure to perform any obligation (other than your payment obligations to Desklamp) under the EULA if the delay or failure is due to unforeseen events, which occur after the effectiveness of the EULA and which are beyond the reasonable control of the parties, such as strikes, blockade, war, terrorism, riots, natural disasters, refusal of license by the government or other governmental agencies, in so far as such an event prevents or delays the affected party from fulfilling its obligations and such party is not able to prevent or remove the force majeure at reasonable cost.   
</p>
<p className = 'terms-incr-p'>
The provisions which in their nature survive the termination of the agreement shall survive in the event of termination of the EULA.  
</p>
{/* <p className = 'terms-p'>
I AGREE  
</p>
<p className = 'terms-p'>
PLEASE CLICK ON THE ABOVE TO CONFIRM YOUR ACCEPTANCE AND AGREEMENT TO THESE TERMS OF USE.    
</p> */}
</div>


export default termsContent