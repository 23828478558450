import React from 'react'
import intel from '../../icons/recognition/intel.png'
import antler from '../../icons/recognition/antler.png'
import tie from '../../icons/recognition/tie.png'
import aws from '../../icons/recognition/aws.png'
import campusfund from '../../icons/recognition/campusfund.png'
import paniit from '../../icons/recognition/paniit.png'
import ecell from  '../../icons/recognition/ecell_iitm.png'

function RecognitionBanner() {
    const imgClass="inline-block"
  return (
      <div className=' text-center mt-10 mb-10	'>
        {/* <div className="inline-block px-3 text-md font-semibold tracking-wider text-grey text-center	  rounded-full">
            Awarded By
        </div> */}
            <div className=" hidden lg:block bg-indigo-50  w-5/6 rounded-lg max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-4 lg:px-8 lg:flex lg:items-center lg:justify-between">
                
                <span className='text-md text-brightDarkPurple'>Recognized by</span>
                <img className={"inline-block h-24"} src={antler} />
                <img className={"inline-block h-10"} src={intel} />
                <img className={"inline-block h-10"} src={aws} />
                <img className={"inline-block h-14"} src={tie} />
                <img className={"inline-block h-10"} src={campusfund} />
                <img className={"inline-block h-20"} src={paniit} />
                <img className={"inline-block h-20"} src={ecell} />

            </div>
        </div>
  )
}

export default RecognitionBanner