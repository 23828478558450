var Name = "Not known";
if (navigator.userAgent.indexOf("Win") !== -1) Name = 
    "Windows";
if (navigator.userAgent.indexOf("Mac") !== -1) Name = 
    "MacOS";
if (navigator.userAgent.indexOf("X11") !== -1) Name = 
    "Linux";
if (navigator.userAgent.indexOf("Linux") !== -1) Name = 
    "Linux";
let regexp = /android|iPhone|kindle|iPad|iOS/i;

let isMobileDevice = regexp.test(navigator.userAgent)
if (isMobileDevice==true) Name = "mobile"
console.log(isMobileDevice)

if(Name === "Not known"){
    Name = "mobile"
}
// Name="Windows"
export var Name

// const osName = navigator.platform
export const srcDict = {
    'MacOS': 'OSIcons/apple.png',
    'Windows': 'OSIcons/windows.png',
    'Linux': 'OSIcons/ubuntu.png',
    'mobile': 'OSIcons/email.png'
}
export const messageDict = {
    'MacOS': 'Download for macOS',
    'Windows': 'Coming Soon for Windows',
    'Linux': 'Coming soon for Linux',
    'mobile': 'Get Download Link'
}

export const urlDict = {
    'MacOS':'https://kitab-beta.s3.ap-south-1.amazonaws.com/Desklamp-latest.dmg',
    'Windows':'https://kitab-beta.s3.ap-south-1.amazonaws.com/Desklamp_Setup_latest.exe'
}

export const src = srcDict[Name]
export const message = messageDict[Name]
export const downloadLink = urlDict[Name]