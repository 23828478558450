import React from 'react';
import { Link } from 'react-router-dom';

import PostImage01 from '../../../icons/features/homehero.png';
import NewsAuthor04 from '../../../icons/team/akash.png'

function BlogList() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 pb-0 md:pt-20 md:pb-0">

          {/*  Page header */}
          <div className="max-w-4xl pb-12 md:pb-10 text-center align-middle justify-center md:text-left">
            <h1 className="h1 text-5xl font-bold text-darkPurple" data-aos="fade-up">Desklamp is the new way to read</h1>
            <div className="text-3xl mt-10 text-textGray">And this is our blog.</div>
            
          </div>

          {/*  Featured article */}
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <div className="relative block group" data-aos="fade-right" data-aos-delay="200">
                <div className="absolute inset-0 bg-indigo-200 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-6 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
                {/* <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out"> */}
                  <Link to="/blog-post">
                    <img className=" inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out shadow-xl" src={PostImage01} width="540" height="303" alt="Blog post 01" />
                  </Link>
                {/* </figure> */}
              </div>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <span className="select-none inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600  transition duration-150 ease-in-out">Product</span>
                      </li>
                      <li className="m-1">
                        <span className="select-none inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500  transition duration-150 ease-in-out">Company</span>
                      </li>
                    </ul>
                  </div>
                  <h3 className="h3 text-2xl lg:text-3xl mb-2">
                    <Link to="/blog-post" className="hover:text-brightDarkPurple transition duration-150 ease-in-out">Introducing Desklamp: The New Way to Read</Link>
                  </h3>
                </header>
                <p className="text-lg text-textGray grow">Why are we called Desklamp? What's our journey been like so far? Read on to learn more!</p>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img className="rounded-full shrink-0 mr-4" src={NewsAuthor04} width="40" height="40" alt="Author 04" />
                  </Link>
                  <div>
                    <Link to="#" className="font-medium text-brightPurple hover:text-brightDarkPurple transition duration-150 ease-in-out">Akash Anand</Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Jan 19, 2022</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>


        </div>
      </div>
    </section>
  );
}

export default BlogList;