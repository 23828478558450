import React from 'react';

import FeatImage from '../../../icons/features/sampleFeature.png';
import annotPane from '../../../icons/features/annotPane.png';
import annotPane2 from '../../../icons/features/annotPane2.png';

import notes from '../../../icons/features/notes.png';
import navigate from '../../../icons/features/navigate.png';

import FeatureHero from './FeatureHero';

function FeaturesZigzag() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 ">

          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach goals that matter</div>
            <h1 className="h2 mb-4">One product, unlimited solutions</h1>
            <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum — semper quis lectus nulla.</p>
          </div> */}

          {/* Items */}
          <div className="grid gap-20">

            {/* 1st item */}
            <FeatureHero 
                image={notes} 
                caption ={"Remember everything you read"}
                title={"Make beautiful margin notes"}
                description={"Writing notes is tedious and time-consuming. At least it used to be. Experience note-making convenience like never before. Desklamp makes you feel like making notes. "}
                featurePoints = {
                    [
                        "Easily mark out excerpts to be added to your notes",
                        "Link your notes back to their context in the document.",
                        "Automatically keeps track of where excerpts came from.",
                    ]
                }
            />

            {/* 2nd item */}
            <FeatureHero 
                image={annotPane2} 
                caption ={"Revise faster."}
                title={"Find your highlights faster"}
                description={"Don't waste time scrolling through pages looking for your highlights, underlines, or sticky notes. Desklamp helps you view and search through them all in one place. "}
                featurePoints = {
                    [
                        "Search through your annotations by text.",
                        "Find things faster by filtering by colour, page number, or annotation type",
                        "Easily navigate to the annotation you're looking for."
                    ]
                }
            />

            {/* 3rd item */}
            <FeatureHero 
                image={navigate} 
                caption ={"Navigate like a pro."}
                title={"Stop scrolling like a maniac"}
                description={"Important information can be scattered across pages. Don't ruin your mouse scrolling back and forth. Desklamp's powerful navigation tools help you jump to pages faster. "}
                featurePoints = {
                    [
                        "Back button to jump to where you last were.",
                        "Location markers to quickly jump through sections in the PDF",
                        "Clipboard to view images along with their explanations side-by-side"
                    ]
                }
            />

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;