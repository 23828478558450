import React from 'react';
import { Link } from 'react-router-dom';

import NewsImageInner from '../../../icons/features/sampleFeature.png';
import NewsImageSingle from '../../../icons/features/sampleFeature.png';
import NewsAuthor04 from '../../../icons/team/akash.png';

function BlogSingle(props) {
    /**
     * @props footer: true or false
     * @props postContent: Content of the post
     */

    const blogSubheader = "after:content-[''] after:table after:clear-both"
    const columnClass = "float-left m-0 auto table h-16"
    const columnContainerClass = "align-middle table-cell"
    // const profilepic-container = ""
    const authorContainer = "w-7/10 float-left after:w-1/5 after:content-[''] after:table after:clear-both "
    const profilePic = "mt-3"

    return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6  ">
        <div className="pt-12 pb-12 md:pt-20 md:pb-20">
          <div className="max-w-3xl mx-auto">

            <article>

              <header className="mb-8">
                {/* Title and excerpt */}
                <div className="text-center md:text-left">
                  <h1 className="h1 mb-4 text-4xl" data-aos="fade-up">{props.title}</h1>
                  <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">{props.excerpt}</p>
                </div>
                {/* Article meta */}
                <div className="md:flex md:items-center md:justify-between mt-3">

                  {/* Author meta */}
                  <div className="flex items-center justify-center mt-5" data-aos="fade-up" data-aos-delay="400">
                    
                    <div className={blogSubheader}>
                        <div className={`profilepic-container ${columnClass}`}>
                            <Link to="#">
                            <img className={`${profilePic} rounded-full shrink-0 mr-4`} src={NewsAuthor04} width="40" height="40" alt="Author 04" />
                            </Link>
                        </div>  
                        
                        <div className={`${authorContainer} ${columnClass}`}>
                            <div className={`${columnContainerClass}`}>
                                <p className="font-medium text-text-darkPurple hover:text-brightPurple transition duration-150 ease-in-out">
                                {props.author}
                                </p>
                                <p className="text-brightDarkPurple">{props.authorRole}</p>
                            </div>
                            
                        </div>
                    </div>
                    
                  </div>


                  {/* Date */}
                  <span className="ml-5 text-brightDarkPurple">15 November, 2021</span>

                </div>
              </header>

              {/* Article image */}
              {
                  props.featureImage?
                  <figure className="mb-8 lg:-ml-32 lg:-mr-32" data-aos="fade-up" data-aos-delay="600">
                    <img className="w-full" src={NewsImageSingle} width="1024" height="576" alt="News single" />
                  </figure>
                  :null
              }
              

              {/* Article content */}
              <div className="text-darkGray text-lg">
                    {props.postContent}
              </div>

              {/* Article footer */}
              {
                props.footer?
                    <footer>
                    <div className="md:flex md:items-center md:justify-between text-center md:text-left">
                    <div className="text-lg text-gray-400 italic">Originally published at <Link to="https://cruip.com/" className="text-gray-200">https://cruip.com/</Link>.</div>
                    <ul className="inline-flex mt-4 md:ml-4 md:mb-0">
                        <li>
                        <Link to="#" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                            </svg>
                        </Link>
                        </li>
                        <li className="ml-4">
                        <Link to="#" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                            </svg>
                        </Link>
                        </li>
                        <li className="ml-4">
                        <Link to="#" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out">
                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                            </svg>
                        </Link>
                        </li>
                    </ul>
                    </div>
                    </footer>
                :null
              }
              
            </article>

          </div>

       

        </div>
      </div>
    </section>
  );
}

export default BlogSingle;