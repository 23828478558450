import React, { Component } from 'react'
import TestimonialsArrows from '../../Components/Testimonials/TestimonialsArrows'
import Hero from './Hero'
import FeaturesZigzag from './Partials/FeaturesZigzag'
import RecognitionBanner from './RecognitionBanner'
import {testimonials} from './HomeData'
import FeaturesBlocks from './Partials/FeaturesBlocks'
import HeroHome from './HeroHome'
import StoreBanner from '../../Components/StoreBanner/StoreBanner'
import Video from './Video'
// import VideoThumbnail from './Partials/VideoThumbnail'

export class Home extends Component {
  render() {
    return (
        <div>
            {/* <div className="producthunt-container flex justify-center pt-4">
            <a rel="noreferrer" 
                href="https://www.producthunt.com/posts/desklamp-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-desklamp&#0045;2" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=352110&theme=light" alt="Desklamp - Remember&#0032;everything&#0032;you&#0032;read&#0032;and&#0032;revise&#0032;faster | Product Hunt" 
                className="producthunt" style={{width: "200px", height: "54px"}} width="250" height="54" /></a>

            </div> */}
            <HeroHome />
            {/* <StoreBanner /> */}
            <RecognitionBanner />

            <FeaturesZigzag />

            <FeaturesBlocks/>
            <Video/>
            <TestimonialsArrows items = {testimonials} />

        </div>
        

        
    )
  }
}

export default Home