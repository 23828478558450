import pesu from '../../icons/clients/pesu.png'
import iitk from '../../icons/clients/iitk.png'
import iitm from '../../icons/clients/iitm.png'
import lse from '../../icons/clients/lse.png'
import glc_mum from '../../icons/clients/glc_mum.png'
import nlu_delhi from '../../icons/clients/nlu_delhi.png'


export const testimonials = [
    {
      quote: '“ Desklamp has been a dramatic change in the way I read ebooks and PDFs. I am now so addicted to it that it got me a wonderful habit of writing notes even when I am reading novels! It has truly changed my way of life!   “',
      name: 'Ayush Jamdar',
      role: 'BTech Electrical Engineering (Class of 2024) at',
      team: 'IIT Madras',
      link: '#0',
      icon: iitm,
      width: 'w-12'
    },
    {
      quote: '“ Desklamp is a unique solution for anyone with rigorous academic reading requirements. Its ability to combine a highly customisable reader with note-making capabilities made it a go-to tool for my research and exam preparation needs.“',
      name: 'Rayan Bhattacharya',
      role: 'LLM Distinction (Class of 2022) from',
      team: 'London School of Economics',
      link: '#0',
      icon: lse,
      width: 'w-28'
    },
    {
      quote: "“ Desklamp's notebook and linking features are a huge help in keeping track of minute details across lengthy documents. I would highly recommend its usage for academic and co-curricular purposes.“",
      name: 'Khushboo Chomal',
      role: 'BLS LLB (Class of 2022) from',
      team: 'Government Law College Mumbai',
      link: '#0',
      icon: glc_mum,
      width: 'w-12'
    },
    {
        quote: "“Desklamp makes analysing judgements and case commentaries really easy thanks to convenient highlighting, note making and word search tools. Desklamp facilitates long term studying as it allows users to cut down relevant portions for easy revision.“",
        name: 'Tapas Devaiah',
        role: 'BA LLB (Class of 2026) at',
        team: 'National Law University Delhi',
        link: '#0',
        icon: nlu_delhi,
        width: 'w-12'
    },
    {
        quote: "“The concept of focussed note making is baked powerfully into Desklamp so that you have to put lesser effort & get the maximum value in each revision. Desklamp makes summarising really fast; I went from research paper to rockstar PPT in 1 hour.“",
        name: 'Ankit Sanghvi',
        role: 'BTech Civil Engineering (Class of 2023) at',
        team: 'IIT Madras',
        link: '#0',
        icon: iitm,
        width: 'w-12'
    },
    {
        quote: "“Desklamp is a very useful product and has become an integral part of my day. The ease of making notes and collaborating on a single piece of writing has never been easier. Desklamp has helped me regain my long lost reading habit.“",
        name: 'Aryan Pandey',
        role: 'BTech Ocean Engineering (Class of 2023) at',
        team: 'IIT Madras',
        link: '#0',
        icon: iitm,
        width: 'w-12'
    },
    {
        quote: "“Studying used to be so boring before Desklamp. I’ve started reading so much more now!“",
        name: 'Sanjana Kanaparthi',
        role: 'BTech Electrical Engineering (Class of 2023) at',
        team: 'IIT Kanpur',
        link: '#0',
        icon: iitk,
        width: 'w-12'
    },
  ]