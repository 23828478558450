
import akash from '../../icons/team/akash.png'
import abhinav from '../../icons/team/abhinav.png'
import prajwal from '../../icons/team/prajwal.png'
import gaurav from '../../icons/team/gaurav.png'
import guneet from '../../icons/team/guneet.png'
import neel from '../../icons/team/neel.png'
import nikhil from '../../icons/team/nikhil.png'
import aman from '../../icons/team/aman.png'

import gizmo from '../../icons/team/gizmo.png'

export const people = [
    //default values twitter: true, linkedin: true
    {
        name: "Akash Anand",
        role: "Co-founder, CEO",
        altrole: "Day Dreamer",
        image: akash,
        twitter: "https://twitter.com/_akashanand",
        linkedin:"https://www.linkedin.com/in/theakashanand/",
    },
    {
        name: "Prajwal Prakash",
        role: "Co-founder, CTO",
        altrole: "Nocturnal Wolf",
        image: prajwal,
        linkedin: "https://www.linkedin.com/in/prajwalprakash2000/",
    },
    {
        name: "Neel Balar ",
        role: "Co-founder, CBO",
        image: neel,
        twitter: "https://twitter.com/BalarNeel",
        linkedin: "https://www.linkedin.com/in/neelbalar7/",
    },
    {
        name: "Aman Kulwal",
        role: "Front-end Engineer",
        image: aman,
        linkedin: "https://www.linkedin.com/in/aman-kulwal-ab8a39212",
    },
    {
        name: "Gizmo",
        role: "Chief Motivational Officer",
        image: gizmo,
    }
]

export const alumni = [

   {
        name: "Abhinav Hampiholi",
        role: "Co-founder",
        altrole: "Wall Gazer",
        image: abhinav,
        twitter: "https://twitter.com/hampiholey",
        linkedin: "https://www.linkedin.com/in/abhinav-hampiholi-a459771b4/"
    },
    {
        name: "Gaurav Vaidya",
        role: "Co-founder",
        altrole: "Opportunity Grabber",
        image: gaurav,
        twitter: "https://twitter.com/thickgrvy",
        linkedin: "https://www.linkedin.com/in/grvvdy/"
    }

]

export const employeeTestimonials = [
    {
      img: prajwal,
      alt: 'Testimonial 01',
      quote: '“ I feel grateful that Desklamp pushed me to expand my horizons and strive to accomplish more. I can proudly say I am no longer a sleeper.“',
      name: 'Prajwal',
      role: 'Backend Developer'
    },
    {
      img: abhinav,
      alt: 'Testimonial 02',
      quote: '“ Not only in my career but in education as well, I feel grateful that Desklamp pushed me to expand my horizons and strive to accomplish more.“',
      name: 'Abhinav Hampiholi',
      role: 'Head of Design'
    },
    {
      img: akash,
      alt: 'Testimonial 03',
      quote: '“ I feel grateful that Desklamp pushed me to expand my horizons and strive to accomplish more, not only in my career but in education as well.“',
      name: 'Akash Anand',
      role: 'Backend Lead'
    }
  ]


  