import React, { Component } from 'react'
import {Link, NavLink} from 'react-router-dom'

import logo from '../../icons/desklamp_logo_white.svg'

export class Navbar extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
        showMenu: false
      }
    }

    toggleMenu = () =>{
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        console.log("currentURL: ", window.location.href)
        const currentPageArray=window.location.href.split("/")
        const currentPage = currentPageArray[currentPageArray.length - 1]
        console.log("currentPage: ", currentPage)

        return (
        <nav className="">
        <div className=" w-5/6 rounded-md bg-brightPurple max-w-7xl mx-auto my-5 px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    {/* <!-- Mobile menu button--> */}
                    <button onClick = {this.toggleMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    {/* <!--
                        Icon when menu is closed.

                        Heroicon name: outline/menu

                        Menu open: "hidden", Menu closed: "block"
                    --> */}
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill={`white`} viewBox="0 0 24 24" stroke="white" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    {/* <!--
                        Icon when menu is open.

                        Heroicon name: outline/x

                        Menu open: "block", Menu closed: "hidden"
                    --> */}
                    <svg  className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex-shrink-0 flex items-center">
                        <a href="/">
                            <img className="block h-6 w-auto" src={logo} alt="Desklamp"/>
                        </a>
                        {/* {logo} */}
                    </div>
                    <div className={`hidden sm:block sm:ml-6`}>
                        <div className="flex space-x-4">
                            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                            <a href="/" className={`text-white hover:bg-brightDarkPurple ${currentPage===""?"bg-indigo-700":""}  text-white px-3 py-2 rounded-md text-sm font-medium`} aria-current="page">Home</a>
                            <a href="https://store.desklamp.io" target="_blank" rel="noreferrer" className={`text-white hover:bg-brightDarkPurple ${currentPage==="store"?"bg-indigo-700":""}  text-white px-3 py-2 rounded-md text-sm font-medium`} aria-current="page">Store</a>

                            {/* <a href="features" className={`text-white hover:bg-brightDarkPurple ${currentPage==="features"?"bg-indigo-700":""} hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>Features</a> */}

                            <a href="blog" className={`text-white hover:bg-brightDarkPurple ${currentPage==="blog"?"bg-indigo-700":""} hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>Blog</a>
                            <a href="support" className={`text-white hover:bg-brightDarkPurple ${currentPage==="support"?"bg-indigo-700":""} hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>Support </a>

                            <a href="team" className={`text-white hover:bg-brightDarkPurple ${currentPage==="team"?"bg-indigo-700":""} hover:text-white px-3 py-2 rounded-md text-sm font-medium`}>About </a>

                        </div>
                        
                    </div>
                    
                </div>
                <div className="flex hidden md:block absolute right-0 px-2 border border-white rounded-md py-2 hover:bg-white transition-colors hover:text-brightPurple	 text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <a href="https://app.desklamp.io" target="_blank" rel="noreferrer">Open Desklamp</a>

                </div>
                
            </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div className={`${this.state.showMenu?"block":"hidden"} sm:hidden`} id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <a href="/" className={`${currentPage===""?"bg-lightPurple":""} text-textGray hover:text-white hover:bg-brightDarkPurple block px-3 py-2 rounded-md text-base font-medium`} aria-current="page">Download</a>
            <a href="https://store.desklamp.io" target="_blank" rel="noreferrer" className={`${currentPage==="store"?"bg-lightPurple":""} text-textGray hover:text-white hover:bg-brightDarkPurple block px-3 py-2 rounded-md text-base font-medium`} aria-current="page">Book Store</a>


            <a href="/blog" className={`${currentPage==="blog"?"bg-lightPurple":""} text-textGray hover:bg-brightDarkPurple hover:text-white block px-3 py-2 rounded-md text-base font-medium`}>Blog</a>

            <a href="/team" className={`${currentPage==="team"?"bg-lightPurple":""} text-textGray hover:bg-brightDarkPurple hover:text-white block px-3 py-2 rounded-md text-base font-medium`}>About</a>
            </div>
        </div>
        </nav>

        )
    }
}

export default Navbar